var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { NavLink, Outlet, } from 'react-router-dom';
import classNames from 'classnames';
// import CenterWidthLimit from '~/component/center_width_limit/CenterWidthLimit';
import layoutStyle from './index.css';
// import centerWidthLimitStyle from '~/component/center_width_limit/center_width_limit.css';
var NavLinkClass = function (_a) {
    var isActive = _a.isActive;
    return isActive
        ? classNames(layoutStyle.active, layoutStyle.navLink)
        : layoutStyle.navLink;
};
export default (function () { return (_jsxs(_Fragment, { children: [_jsx("header", __assign({ className: classNames(layoutStyle.centerContainer, layoutStyle.topbar) }, { children: _jsxs("nav", __assign({ className: classNames(layoutStyle.widthLimit, layoutStyle.nav) }, { children: [_jsx(NavLink, __assign({ to: "/", className: NavLinkClass }, { children: "Home" })), _jsx("a", __assign({ href: "https://github.com/TylerTemp/docpie/wiki", target: '_blank', className: layoutStyle.navLink }, { children: "Document" })), _jsx(NavLink, __assign({ to: "/try", className: NavLinkClass }, { children: "Try Online" }))] })) })), _jsx("div", __assign({ className: layoutStyle.centerContainer }, { children: _jsx("div", __assign({ className: layoutStyle.widthLimit }, { children: _jsx(Outlet, {}) })) }))] })); });
