import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
var versionTimeReadable = function (versionTime) {
    var date = new Date();
    date.setTime(versionTime * 1000);
    var year = date.getFullYear();
    var monthNum = date.getMonth() + 1;
    var dayNum = date.getDate();
    var hourNum = date.getHours();
    var minNum = date.getMinutes();
    var secNum = date.getSeconds();
    var month = "".concat(monthNum < 10 ? "0" : "").concat(monthNum);
    var day = "".concat(dayNum < 10 ? "0" : "").concat(dayNum);
    var hour = "".concat(hourNum < 10 ? "0" : "").concat(hourNum);
    var min = "".concat(minNum < 10 ? "0" : "").concat(minNum);
    var sec = "".concat(secNum < 10 ? "0" : "").concat(secNum);
    return "".concat(year, "-").concat(month, "-").concat(day, " ").concat(hour, ":").concat(min, ":").concat(sec);
};
export default (function (_a) {
    var getApiVersion = _a.getApiVersion;
    var _b = getApiVersion(), version = _b.version, versionTime = _b.version_time;
    return _jsxs("p", { children: ["docpie version ", version, _jsx("br", {}), "updated at ", versionTimeReadable(versionTime)] });
});
