var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import doubleDotsSpinerStyle from './index.css';
export default (function (_a) {
    var _b = _a.color, color = _b === void 0 ? "#a19f9f" : _b, _c = _a.size, size = _c === void 0 ? 40 : _c, _d = _a.style, style = _d === void 0 ? {} : _d;
    return _jsxs("div", __assign({ className: doubleDotsSpinerStyle.spinner, style: __assign({ width: size, height: size }, style) }, { children: [_jsx("div", { className: doubleDotsSpinerStyle.dot1, style: { backgroundColor: color } }), _jsx("div", { className: doubleDotsSpinerStyle.dot2, style: { backgroundColor: color } })] }));
});
