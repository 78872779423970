var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createElement as _createElement } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from 'query-string';
import Terminal from './Terminal';
import { useState } from "react";
import ReplaceAll from "~/Utils/ReplaceAll";
var defaultDoc = "Naval Fate.\n\nUsage:\n  naval_fate ship new <name>...\n  naval_fate ship <name> move <x> <y> [--speed=<km/h>]\n  naval_fate ship shoot <x> <y>\n  naval_fate mine (set|remove) <x> <y> [--moored|--drifting]\n  naval_fate -h | --help\n  naval_fate --version\n\nOptions:\n  -h -? --help    Show this screen.\n  --version       Show version.\n  --speed=<km/h>  Speed in knots.[default: 10]\n  --moored        Moored (anchored) mine.\n  --drifting      Drifting mine.\n";
// interface Struct {
//     [key: string]: ValueType;
// }
var queryStringify = function (curParams) {
    var queryParams = [];
    for (var key in curParams) {
        if (curParams.hasOwnProperty(key)) {
            var value = curParams[key];
            if (value !== null && value !== false && value !== undefined) {
                var valueInitConvert = value === true
                    ? '1'
                    : value.toString();
                queryParams.push("".concat(encodeURIComponent(key), "=").concat(encodeURIComponent(valueInitConvert)));
            }
        }
    }
    return queryParams.join('&');
};
var ReplaceN = function (doc, replace) {
    if (!replace) {
        return doc;
    }
    // console.log(`replace \\n for ${doc}`);
    // const result = doc.replaceAll('\\n', '\n');;
    // console.log(result);
    // return result;
    // return doc.replace('\\n', '\n');
    return ReplaceAll(doc, '\\n', '\n');
};
export default (function () {
    var _a = useLocation().search, search = _a === void 0 ? '' : _a;
    var _b = useState(search), prevSearch = _b[0], setPrevSearch = _b[1];
    var _c = useState(-1), pageChanged = _c[0], onPageChange = _c[1];
    var navigate = useNavigate();
    var onSubmitCallback = function (curParams) {
        var newQuery = "?".concat(queryStringify(curParams));
        setPrevSearch(newQuery);
        navigate(newQuery);
    };
    var pageKey = pageChanged;
    if (prevSearch !== search) {
        pageKey = -pageKey;
        onPageChange(pageKey);
        setPrevSearch(search);
        console.log("flip page");
        // return <></>;
    }
    var queryParams = queryString.parse(search);
    var hasQueryParams = Object.keys(queryParams).length > 0;
    var replaceN = queryParams.replace !== undefined;
    // console.log(`replaceN=${replaceN}`)
    var pageParams = hasQueryParams
        ? {
            doc: ReplaceN(queryParams.doc, replaceN),
            argvnofilestr: queryParams.argvnofilestr || '',
            help: queryParams.help !== undefined,
            version: queryParams.version === undefined ? null : queryParams.version,
            stdopt: queryParams.stdopt !== undefined,
            attachopt: queryParams.attachopt !== undefined,
            attachvalue: queryParams.attachvalue !== undefined,
            helpstyle: queryParams.helpstyle === undefined ? 'python' : queryParams.helpstyle,
            auto2dashes: queryParams.auto2dashes !== undefined,
            name: queryParams.name === undefined ? null : queryParams.name,
            optionsfirst: queryParams.optionsfirst !== undefined,
            appearedonly: queryParams.appearedonly !== undefined,
            namedoptions: queryParams.namedoptions !== undefined,
            onSubmitCallback: onSubmitCallback,
            // run: queryParams.run !== undefined
        }
        : {
            doc: defaultDoc,
            argvnofilestr: '',
            help: true,
            version: null,
            stdopt: true,
            attachopt: true,
            attachvalue: true,
            helpstyle: 'python',
            auto2dashes: true,
            name: null,
            optionsfirst: false,
            appearedonly: false,
            namedoptions: false,
            onSubmitCallback: onSubmitCallback,
            // run: false,
        };
    return _createElement(Terminal, __assign({}, pageParams, { key: pageKey }));
});
