var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Routes, Route } from 'react-router-dom';
import ErrorBoundary from "~/Components/ErrorBoundary";
import Home from '~/Pages/Home';
import TryDocpie from "~/Pages/TryDocpie";
import NotFound from "~/Pages/NotFound";
import Layout from "~/Components/Layout";
ReactDOM.createRoot(document.getElementById("root")).render(_jsx(ErrorBoundary, { children: _jsx(HelmetProvider, { children: _jsx(Router, { children: _jsx(Routes, { children: _jsxs(Route, __assign({ path: "/", element: _jsx(Layout, {}) }, { children: [_jsx(Route, { index: true, element: _jsx(Home, {}) }), _jsx(Route, { path: "try", element: _jsx(TryDocpie, {}) }), _jsx(Route, { path: "*", element: _jsx(NotFound, {}) })] })) }) }) }) }));
