var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Style from './index.css';
// import Terminal, {Params as TerminalParams} from './Terminal';
import TerminalWrapper from "./TerminalWrapper";
import { Suspense, useMemo, useState } from "react";
import Version from './Version';
import RetryErrorBoundary from "~/Components/RetryErrorBoundary";
import Suspendable from "~/Utils/Suspendable";
import Request from "~/Utils/Request";
export default (function () {
    var _a = useState(1), retry = _a[0], setRetry = _a[1];
    var getApiVersion = useMemo(function () {
        return Suspendable(Request("/").then(function (resp) { return resp.json(); }).then(function (json) { return json; }));
    }, [retry]);
    return _jsxs(_Fragment, { children: [_jsx("h2", __assign({ className: Style.title }, { children: "Try docpie" })), _jsx(RetryErrorBoundary, __assign({ onRetry: function () { setRetry(function (old) { return -old; }); } }, { children: _jsx(Suspense, __assign({ fallback: _jsx("p", { children: "loading" }) }, { children: _jsx(Version, { getApiVersion: getApiVersion }, retry) })) })), _jsx(TerminalWrapper, {})] });
});
