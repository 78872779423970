var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
var ErrorBoundary = /** @class */ (function (_super) {
    __extends(ErrorBoundary, _super);
    function ErrorBoundary() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            error: undefined,
            info: {
                componentStack: '',
            },
        };
        return _this;
    }
    // {onRetry}: {onRetry: ()=> void}
    ErrorBoundary.prototype.componentDidCatch = function (error, info) {
        this.setState({ error: error, info: info });
    };
    ErrorBoundary.prototype.render = function () {
        var _this = this;
        var _a = this.props, message = _a.message, description = _a.description, children = _a.children, onRetry = _a.onRetry;
        var _b = this.state, error = _b.error, info = _b.info;
        var componentStack = info && info.componentStack ? info.componentStack : null;
        var errorMessage = typeof message === 'undefined' ? (error || '').toString() : message;
        var errorDescription = typeof description === 'undefined' ? componentStack : description;
        if (error) {
            return _jsxs(_Fragment, { children: [_jsx("h2", { children: errorMessage }), _jsx("button", __assign({ onClick: function () {
                            _this.setState({
                                error: undefined,
                                info: {
                                    componentStack: '',
                                }
                            });
                            onRetry();
                        } }, { children: "Retry" })), _jsx("hr", {}), _jsx("pre", __assign({ style: { fontSize: '0.9em', overflowX: 'auto' } }, { children: errorDescription }))] });
        }
        return children;
    };
    return ErrorBoundary;
}(React.Component));
export default ErrorBoundary;
